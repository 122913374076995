<template>
  <div>
    <div class="btn" v-if="!disabled">
      <el-button @click="addCol">新增一列</el-button>
      <el-button @click="delCol">减少一列</el-button>
    </div>
    <vxe-table
      border
      resizable
      ref="vtable"
      :show-header="false"
      show-overflow
      :show-footer="showFooter"
      :data="tableData"
      :scroll-x="{
        enabled: true,
      }"
      :footer-span-method="footerColspanMethod"
      :footer-method="footerMethod"
    >
      <vxe-table-column
        min-width="200px"
        v-for="(item, index) in column"
        :key="'data' + index"
        :field="item.field"
      >
        <template v-slot="{ row }">
          <div v-if="item.type === 'control'">
            <el-input-number
              :controls="false"
              v-model="row[item.field]"
              @change="changeValue"
              size="mini"
              class="input-number-left"
              v-if="row.type === 'inputNumber' && !disabled"
            ></el-input-number>
            <span v-if="disabled">
              {{row[item.field] || 0}}
            </span>
            <span v-if="row.unit">
              {{ row.unit }}
            </span>
          </div>
          <div v-else>
            {{ row[item.field] }}
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    showFooter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Array,
      default: () => [
        {
          title: '随机金额',
          unit: '元',
          field: 'randomAmount',
          type: 'inputNumber',
        },
        {
          title: '随机概率',
          unit: '%',
          field: 'randomProbability',
          type: 'inputNumber',
        },
      ],
    },
  },
  watch: {
    showFooter() {
      this.$refs.vtable.refreshColumn();
    },
  },
  computed: {
    tableData() {
      const list = [];
      this.cols.forEach((v) => {
        let data = v;
        this.value.forEach((a, k) => {
          data = {
            ...data,
            [`col${k}`]: a[v.field],
          };
        });
        list.push(data);
      });
      return list;
    },
    column() {
      const col = [
        {
          field: 'title',
        },
      ];
      this.value.forEach((v, k) => {
        col.push({
          field: `col${k}`,
          type: 'control',
        });
      });
      return col;
    },
    rowData() {
      let obj = null;
      this.cols.forEach((v) => {
        obj = {
          ...obj,
          [v.field]: '',
        };
      });
      return obj;
    },
  },
  data() {
    return {};
  },
  methods: {
    handleValue() {
      const list = [];
      this.column.forEach((v) => {
        let data = null;
        if (v.field === 'title') return;
        this.tableData.forEach((a) => {
          data = {
            ...data,
            [a.field]: a[v.field],
          };
        });
        list.push(data);
      });
      return list;
    },
    footerMethod({ columns, data }) {
      console.log(323);
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '加权平均值';
          }
          let total = 0;
          this.value.forEach((v) => {
            total += (v.randomAmount * (v.randomProbability / 100));
          });
          this.$emit('totalData', total);
          return total;
        }),
      ];
      return footerData;
    },
    footerColspanMethod(val) {
      const { $rowIndex, _columnIndex } = val;
      if ($rowIndex === 0) {
        if (_columnIndex === 1) {
          return {
            rowspan: 1,
            colspan: this.column.length - 1,
          };
        }
        if (_columnIndex > 1) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    changeValue() {
      const value = this.handleValue();
      //   this.value = value;
      this.$emit('input', value);
    },
    addCol() {
      const value = this.handleValue();
      value.push(this.rowData);
      //   this.value = value;
      this.$emit('input', value);
    },
    delCol() {
      const value = this.handleValue();
      value.pop();
      //   this.value = value;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="less" scoped>
.btn{
  margin-bottom: 24px;
}
.input-number-left {
  text-align: left;
}
</style>
