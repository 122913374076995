var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "btn" },
            [
              _c("el-button", { on: { click: _vm.addCol } }, [
                _vm._v("新增一列"),
              ]),
              _c("el-button", { on: { click: _vm.delCol } }, [
                _vm._v("减少一列"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "vtable",
          attrs: {
            border: "",
            resizable: "",
            "show-header": false,
            "show-overflow": "",
            "show-footer": _vm.showFooter,
            data: _vm.tableData,
            "scroll-x": {
              enabled: true,
            },
            "footer-span-method": _vm.footerColspanMethod,
            "footer-method": _vm.footerMethod,
          },
        },
        _vm._l(_vm.column, function (item, index) {
          return _c("vxe-table-column", {
            key: "data" + index,
            attrs: { "min-width": "200px", field: item.field },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      item.type === "control"
                        ? _c(
                            "div",
                            [
                              row.type === "inputNumber" && !_vm.disabled
                                ? _c("el-input-number", {
                                    staticClass: "input-number-left",
                                    attrs: { controls: false, size: "mini" },
                                    on: { change: _vm.changeValue },
                                    model: {
                                      value: row[item.field],
                                      callback: function ($$v) {
                                        _vm.$set(row, item.field, $$v)
                                      },
                                      expression: "row[item.field]",
                                    },
                                  })
                                : _vm._e(),
                              _vm.disabled
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(row[item.field] || 0) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              row.unit
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(row.unit) + " "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(" " + _vm._s(row[item.field]) + " "),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }